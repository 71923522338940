@import './../../styles/components/input';

.PhoneInput {
  width: 100%;
  @include inputForm;
  height: toRem(74);
}

.PhoneInputInput {
  flex: 1;
  box-shadow: none;
  outline: none;
  border: none;
  font-size: toRem(16);

  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
}
