// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.action-btn {
  width: toRem(60);
  height: toRem(60);
}

.web {
  display: none !important;
  color: $white;
  padding-left: toRem(20);
}

.mobile {
  display: block !important;
}

.header {
  z-index: 100;
  height: max-content;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: $white;
  transition: height 500ms;
  box-shadow: rgba(0, 0, 0, 0.1) 0 toRem(4) toRem(8) 0;

  * {
    font-size: 1.1rem;
  }

  &__brand {
    display: flex;
    padding: $gap/2 $gap;
  }

  &__logo {
    width: toRem(90);
    transition: width 500ms;

    &__image {
      width: 100%;
      outline: none;
      cursor: pointer;
    }
  }

  &__toggle-btn {
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    padding: 0;

    &.navbar-toggler {
      color: $black;
    }

    &:hover,
    &:active,
    &:visited,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    span {
      margin-right: $gap;
    }
  }

  &__nav {
    transition: max-height 0.5s;
    max-height: 0;
    overflow: hidden;

    &.collapse:not(.show) {
      display: block;
    }

    &.show {
      max-height: toRem(1000);
      transition: max-height 0.5s;
    }

    .navbar-nav {
      padding: 0 toRem(30);
      span {
        display: none;
      }

      a {
        display: block;
        padding: $gap 0;
        width: 100%;
        color: $dark-grey;
      }
    }

    &__container {
      position: absolute;
      width: inherit;
      color: $white;
      &:hover .header__nav__info {
        display: inline-block;
      }
    }

    &__help {
      width: toRem(172);
      text-align: center;

      &-web {
        display: none;
      }
    }

    &__info {
      display: none;
      padding: toRem(10) toRem(10);
      background-color: black;
      width: inherit;

      &-mobile {
        display: block;
        padding: 0 !important;
      }

      &-web {
        display: none;
      }
    }

    &__label {
      display: none;
      padding: toRem(15) toRem(15);

      &-mobile {
        font-weight: bold;
        padding: toRem(5) toRem(5) !important;
      }
    }

    &__schedule {
      font-size: 1.5rem !important;
      color: #bdbdbd;
    }

    &__title {
      padding: toRem(9) toRem(15);
      background-color: $red-re;
      display: flex;
      width: toRem(60);
      height: toRem(60);
      float: right;
      align-items: center;
    }
  }
}

.error-baner {
  width: 100%;
  background-color: $red-re;
  color: $white;
  height: max-content;
  padding: toRem(30);
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.2em;

  &.disabled {
    display: none;
  }

  &__btn {
    float: right;
    background-color: $red-re;
    border: none;
    color: $white;
  }
}

@include media-breakpoint-up(sm) {
  .web {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .header {
    &__logo {
      width: toRem(90);
      transition: width 500ms;

      &__image {
        width: 100%;
      }
    }

    &__nav {
      .navbar-nav {
        padding: 0 toRem(30);
      }

      &__label {
        text-align: start;
        display: block;
        font-size: 1.4em;
        padding: toRem(10) 0;

        &-mobile {
          display: none !important;
        }
      }

      &__schedule {
        text-align: left;
      }

      &__info {
        padding: toRem(15) toRem(15);
      }

      &__help {
        width: toRem(200);
        margin-top: toRem(3);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .web {
    display: block !important;
  }

  .mobile {
    display: none !important;
  }

  .header {
    &__nav {
      max-height: unset;
      padding: 0 $gap * 2;

      &__container {
        box-shadow: 0 toRem(6) toRem(11) rgba(0, 0, 0, 0.25);
      }

      &__links {
        margin: 0 1rem;

        &:hover {
          color: #6c757d;
        }
        &.active {
          a {
            text-decoration: underline;
          }
        }
      }

      &__title {
        float: none;
        width: auto;
        height: auto;
      }
    }
  }
}

.threebar {
  cursor: pointer;
  margin-top: 0;
  margin-left: 0;
  width: inherit;
  height: inherit;
  padding: toRem(15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bar {
    width: toRem(25);
    height: toRem(3);
    background: $red-re;
    margin-bottom: toRem(3);
    transition: all 0.5s ease;
    &:nth-child(1) {
      transform-origin: 50%;
    }
  }
  .gap {
    margin-left: toRem(3);
  }
  &.arrow {
    .bar:nth-child(1) {
      margin-left: 0;
      transform: scaleX(0.5) translateX(100%) rotate(25deg);
      transform: translateY(500%) rotate(-45deg) scaleX(0.75) translateX(65%);
    }
    .bar:nth-child(2) {
      transform: scaleX(0.5) translateX(-100%) rotate(25deg);
      transform: translateX(-10%) rotate(45deg) scaleX(0.75);
    }

    .bar:nth-child(3) {
      margin-left: 0;
      opacity: 0;
      transform: translateX(-25%) translateY(-175%) scaleX(0.75);
    }
  }
}

.brand {
  width: toRem(90);
  transition: width 500ms;

  &__image {
    width: 100%;
    outline: none;
    cursor: pointer;
  }
}

.nav-link.active {
  text-decoration: underline;
}

.container_nav {
  flex-direction: row !important;
}

.contact_nav_link {
  background-color: $red-re;
  color: $white;
  text-transform: uppercase;
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}
.contact_nav_link:hover {
  color: $white;
}

.contact_nav_link_text {
  padding-left: toRem(5);
  color: $white;
}

.contact_nav_link.active {
  text-decoration: none;
  color: $white !important;
}
.nav-link {
  font-size: toRem(18);
}

.nav-link-default {
  @include media-breakpoint-up(lg) {
    padding-left: toRem(25) !important;
    padding-right: toRem(25) !important;
  }
}

.contact_link_mobile {
  background-color: $red-re;
  width: toRem(60);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.dropdown-item:active {
  background-color: $red-re;
}

.dropdown-item.active {
  background-color: $red-re;
}
