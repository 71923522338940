@import './../../../assets/styless/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

main {
  padding-top: 60px;
}

.bg-normal {
  background-color: $background;
}

.bg-milk {
  background-color: $milk;
}

.bg-grey {
  background-color: $grey;
}

.bg-white {
  background-color: $white;
}

.cl-secondary {
  color: $secondary;
}

.child-page {
  &__banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    padding: 3rem 0;
    position: relative;

    h1 {
      color: $white;
      font-size: 3.2rem;
    }
  }
}

@include media-breakpoint-up(sm) {
  main {
    padding-top: 90px;
  }

  .child-page {
    &__banner {
      padding: 12rem 0;

      h1 {
        font-size: 4.8rem;
      }
    }
  }
}
