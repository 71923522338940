// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

$font-family-base: 'Itim', 'Arial Narrow', Arial, sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

@font-face {
  font-family: BrauerNeueRegular;
  src: url('../../fonts/BrNStdRg.otf') format('opentype');
}

@font-face {
  font-family: BrauerNeueBold;
  font-weight: bold;
  src: url('../../fonts/BrNStdBd.otf') format('opentype');
}
