// -----------------------------------------------------------------------------
// This file contains all styles related to the choice component.
// -----------------------------------------------------------------------------
.choice_content {
  position: relative;
  background-size: cover !important;
  height: 444px;
  width: 90%;
  display: flex;
  margin-bottom: 50px;
  margin-left: 1rem;
  margin-right: 1rem;
  &__section {
    position: absolute;
    bottom: 0;

    &-title {
      color: $white;
      font-size: toRem(38);
      text-align: left;
      font-weight: 500;
      width: 100%;
      padding-left: 20px;
    }

    &-detail {
      padding-left: 20px;
      color: $white;
      font-size: toRem(21);
      width: 100%;
      text-align: left;
    }

    &-button {
      border-style: solid;
      border-width: 1px;
      color: $white !important;
      font-size: toRem(18);
      font-weight: 700;
      padding: 10px;
      text-transform: uppercase;
      align-self: flex-end;
      margin: 20px;
    }
  }
  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    width: 369px;
    margin-left: 10px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }
}
