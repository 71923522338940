.stepper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px;
}

.stepper-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper-children {
  width: 100%;
}

.step-wrapper {
  width: 100%;
  height: 100%;
}

.stepper-selector {
  position: absolute;
  height: 100%;
  display: inline-flex;
  top: 0;
}

.stepper-progess-wrapper {
  display: flex;
  margin-bottom: 3.125rem;
  box-shadow: 0 4px 2px -2px $grey;
  flex: 1;
}

.stepper-progress-container {
  display: flex;
  flex-direction: column;
}

.stepper-progress-step {
  margin: 0 0.05rem;
  font-size: toRem(18);
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex: 1;
}

.stepper-progress-step.disabled {
  cursor: default;
}

.stepper-progress-icon {
  margin: 0 5px;
  width: 20px;
}

.step-done {
  background: transparent;
  &::before,
  &::after {
    background: transparent !important;
    border: none !important;
  }
  color: $black !important;
}

.step-current {
  &::before {
    background-color: $red-re !important;
    border: 0 !important;
  }
  &::after {
    background-color: $red-re !important;
    border: 0 !important;
  }
  color: $white !important;
}

.stepper-progress-content {
  position: relative;
  z-index: 1;
  display: flex;
  color: $lightBlack;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.stepper-progress-skein {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  bottom: 0;
  left: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    color: $white;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    transform: skewX(12deg);
    border: 1px solid $grey;
    border-bottom: none;
    background: $lightGray;
  }

  &::after {
    right: 0;
    top: unset;
    left: 0;
    bottom: 0;
    transform: skewX(-12deg);
    border: 1px solid $grey;
    border-top: none;
  }
}

@include media-breakpoint-down(sm) {
  .stepper-progress-content {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .stepper-progress-step {
    font-size: 1.125rem;
  }

  .stepper-progress-wrapper {
    width: 100%;
    overflow: auto;
  }

  .stepper-progress-container {
    width: 100%;
    overflow: auto;
  }
}
