@mixin inputForm {
  padding: toRem(16);
  font-size: toRem(16);
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid $gray;
  box-sizing: border-box;
  line-height: 1.2;
}

.inp {
  @include inputForm;
  &:disabled {
    background-color: $disabled;
    color: $lightBlack;
  }
}

/**
  Switch
   */
.input-switch {
  grid-column: 1/8;
  font-size: 1.8rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 4rem 0;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
