.step {
  margin: $gap/7 0;
  width: 100%;
  position: relative;
  p,
  span {
    color: $black;
  }

  &__chevron {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    bottom: 0;
    left: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $white;
      top: 0;
      left: 0;
      width: calc(50% + 1px);
      height: 100%;
      transform: skewY(6deg);
    }

    &::after {
      left: unset;
      right: 0;
      transform: skewY(-6deg);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 3.2rem 2rem;
    line-height: 1.3;

    * {
      text-align: left;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.6rem;

      span {
        font-size: toRem(48);
        margin-right: 5px;
      }

      img {
        width: 7rem;
        height: 7rem;
      }
    }

    &__title {
      padding: 1rem 0 1rem 0;
      font-size: toRem(18);
      color: $red-re;
    }

    &__detail {
      font-size: toRem(18);
    }
  }
}

@include media-breakpoint-up(lg) {
  .step {
    margin: 0 $gap/5;

    &__chevron {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;

      &::before,
      &::after {
        content: '';
        top: 0;
        left: 0;
        height: 50%;
        width: 100%;
        transform: skewX(6deg);
      }

      &::after {
        top: unset;
        left: 0;
        bottom: 0;
        transform: skewX(-6deg);
      }
    }

    &__content {
      &__icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
