// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  background-color: $dark-grey;
  color: $white;
  padding: $gap * 3 0;
  line-height: 1.5;
  font-size: 1rem;

  a {
    color: $white;
  }

  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $white;
    padding-bottom: $gap;
    margin-bottom: $gap;
  }

  &__contact {
    font-size: 1rem;
    margin-bottom: $gap;

    p {
      b {
        font-weight: bold;
      }
      a {
        margin-left: 5px;
      }
    }
  }

  .ico {
    height: 25px;
    width: 25px;
    background-color: $white;
    margin: 2px;
    border-radius: 2px;
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;

    &--fb {
      background-image: url('./../../../assets/images/ico-fb.png');
    }

    &--tw {
      background-image: url('./../../../assets/images/ico-tw.png');
    }

    &--in {
      background-image: url('./../../../assets/images/ico-in.png');
    }

    &--yb {
      background-image: url('./../../../assets/images/ico-yb.png');
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 1rem;

    &__contact {
      font-size: 1rem;
    }

    .col-12 {
      margin-bottom: $gap * 2;
    }
  }
}
