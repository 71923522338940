@keyframes fixedChange {
  0% {
    opacity: 0;
    top: 30%;
    left: 90%;
    transform: translate(-100%, -50%);
  }

  100% {
    opacity: 1;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
}

@keyframes normalBlock {
  0% {
    opacity: 0;
    position: absolute;
    top: 150%;
    right: 0;
    transform: translateY(-80%);
    z-index: 999;
  }

  100% {
    opacity: 1;
    position: absolute;
    top: 100%;
    transform: translateY(-80%);
    z-index: 1;
  }
}

@mixin collapse {
  padding: 0;
  width: toRem(60);
  @include media-breakpoint-up(md) {
    padding: 0 1.6rem 0 0;
    width: toRem(160);
  }
  transition: 0.3s;
  cursor: pointer;

  .home-banner__question__icon {
    padding: toRem(22) 0.6rem;
    &::after {
      position: absolute;
      display: inline;
      content: '';
      width: 0;
      max-height: 0;
      flex: 0;
      transition: 0.3s;
    }
  }

  .home-banner__question__content {
    transition: 0.3s;
  }

  .home-banner__question__title {
    font-size: toRem(14);
    font-weight: 400;
    margin-bottom: 0;
    transition: 0.3s;
    text-transform: uppercase;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }

    button {
      display: none;
    }
  }

  .home-banner__question__detail {
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0, all 0.3s;
  }
}

.home-banner {
  &__question {
    background-color: $dark-grey;
    color: $white;
    display: flex;
    padding: 1.8rem;
    width: 90%;
    max-width: toRem(660);
    font: unset;
    position: absolute;
    top: 100%;
    transform: translateY(-80%);
    z-index: 1;
    overflow: hidden;
    transition: 0.3s;

    &__title {
      display: flex;
      justify-content: space-between;
      font-size: toRem(28);
      font-weight: 600;
      margin-bottom: 1.3rem;
      transition: 0.3s;

      button {
        background: none !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        display: inline-block;

        svg {
          width: 1.6rem;
          height: 1.6rem;
          .st0 {
            fill: $white;
          }
        }
      }
    }

    &__detail {
      font-size: toRem(16);
      line-height: 1.5;
      overflow: hidden;
      max-width: toRem(1000);
      max-height: toRem(500);
      opacity: 1;
      visibility: visible;
      transition: all 0.3s, opacity 0.2s 0.2s ease;
    }

    &__icon {
      flex: 1;
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      transition: 0.3s;
      &::after {
        content: '';
        display: block;
        position: relative;
        width: 0;
        border-right: 1px solid $white;
        max-height: toRem(1000);
        flex: 1;
        margin-top: 0.8rem;
        transition: 0.3s;
      }
    }

    &__content {
      margin-top: 0;
      padding-top: 1.5rem;
      transition: 0.3s;
    }

    &.fixed-block {
      z-index: 999;
      animation: fixedChange 0.5s;
      position: fixed;
      top: 50%;
      left: 100%;
      transform: translate(-100%, -50%);

      button {
        display: inline-block;
      }

      &.collapsed {
        @include collapse();
      }
    }

    &.normal-block {
      position: absolute;
      top: 100%;
      transform: translateY(-80%);
      z-index: 1;
      animation: normalBlock 0.5s;
      animation-direction: alternate;
    }
  }
}

@include media-breakpoint-up(sm) {
  .home-banner {
    &__question {
      padding: 2rem;
      max-width: toRem(660);

      &.normal-block {
        right: var(--question-block-right);
      }

      &__title {
        font-size: toRem(28);
      }

      &__detail {
        font-size: toRem(16);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .home-banner {
    &__question {
      padding: 2rem;
      right: 5rem;
      width: 66rem;
      max-width: toRem(660);

      &__title {
        font-size: toRem(28);
      }

      &__detail {
        font-size: toRem(16);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .home-banner {
    &__question {
      padding: 2.4rem;

      &.normal-block {
        right: toRem(50);
      }

      &__icon {
        padding-top: 0.2rem;
      }

      &__title {
        font-size: toRem(28);
      }

      &__detail {
        font-size: toRem(16);
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .home-banner {
    &__question {
      transform: translateY(-80%);
    }
  }
}

@include media-breakpoint-down(xxs) {
  .home-banner {
    &__question {
      transform: translateY(-80%);
    }
  }
}
