.choice-section {
  &__options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.2rem;
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(xl) {
    &__options {
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }
}
