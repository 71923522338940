.item {
  width: 369px;
  padding: 2.5rem 2rem;
  margin: 8px;
  line-height: 1.3;
  &__title {
    font-size: toRem(28);
  }

  &__price {
    color: $black;
    font-size: toRem(28);
    font-weight: 600;
  }
}
