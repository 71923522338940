.benefit {
  width: 100%;
  background-color: $white;
  margin: 10px;
  text-align: center;
  &__icon {
    padding: 20px;
  }
  &__title {
    font-size: toRem(18);
    font-weight: bold;
    color: $black;
    padding: 0 16px 0 16px;
  }
  &__detail {
    font-size: toRem(18);
    color: $black;
    padding: 0 16px 0 16px;
  }
  @include media-breakpoint-up(md) {
    width: 370px;
    height: 324px;
  }
}
