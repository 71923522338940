// Colors
$primary: #ba0029;
$secondary: #ba0029;

$boxShadowColor: #d6d6d6;
$white: white;
$gray: #bdbdbd;
$light: #fbfbfb;
$grey: #e5e5e5;
$background: #f7f7f7;
$label: #9b9b9b;
$labelBlurred: #bdbdbd;
$line: lighten($label, 30%);
$text: #333333;
$black: black;
$lightBlack: #828282;
$lightGray: #f6f7f8;
$blue: #007e9d;
$red: red;
$graph1: #005b89;
$graph2: #f18815;
$graph3: #e0e0e0;
$graph4: #ffd500;
$graphHot: #c1140e;
$graphAbo: #86bc25;

$graphText1: #6cdd2f;
$graphText2: #864f8f;
$graphText3: #ff3b36;
$graphText4: #ff8044;
$graphTextAbo: #aac63b;

$error: #fd4141;
$success: #6fcf97;
$disabled: rgb(242, 242, 242);

$milk: #e8e8e8;

// Other
$gap: 15px;

$borderRadius5: 5px;

$shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

$grid-breakpoints: (
  xxs: 330px,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$grid-gutter-width: 1.5rem !default;

$container-padding-x: $grid-gutter-width / 2 !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
