// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
@font-face {
  font-family: BrauerNeueRegular;
  src: url('../../fonts/BrNStdRg.otf') format('opentype');
}

@font-face {
  font-family: BrauerNeueBold;
  font-weight: bold;
  src: url('../../fonts/BrNStdBd.otf') format('opentype');
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'BrauerNeueRegular', 'HelveticaNeue', Helvetica Neue, Helvetica,
    Arial, sans-serif !important;
}
/**
   * Basic styles for links
   */
a {
  color: $red-re;
  text-decoration: none;
}
