.section-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
}
