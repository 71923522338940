.contact-bloc {
  color: white;
  width: toRem(275);
  padding: toRem(15);
  margin-bottom: toRem(20);
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(md) {
    margin-left: unset;
    margin-right: unset;
  }
  &__title {
    font-size: toRem(18);
    margin-left: toRem(10);
    text-transform: uppercase;
  }
  &__phone-number {
    padding-top: toRem(30);
    font-size: toRem(24);
    margin-bottom: 0;
  }
  &__schedules {
    font-size: toRem(16);
    color: #bdbdbd;
    margin-bottom: 0;
  }
}

.contact-bloc-mail {
  max-width: toRem(600);
  color: $white;
  padding: toRem(30);
  &__header {
    font-size: toRem(18);
    svg {
      margin-right: toRem(20);
    }
  }
  &__title {
    font-size: toRem(18);
    text-transform: uppercase;
    margin-bottom: auto;
    margin-top: auto;
  }
  &__email {
    text-decoration: underline;
    font-size: toRem(28);
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__description {
    color: $gray;
  }
}
