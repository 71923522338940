/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error {
  color: $primary;
  margin-top: 0.5rem;
  font-size: 20px;
}

.common-input {
  display: flex;
  padding-top: toRem(10);
  padding-bottom: toRem(10);
  outline-color: $red-re;

  &__label {
    display: flex;
  }

  &__label,
  &__link {
    flex: 1;
    align-items: center;
    font-size: toRem(18);
  }

  &__input,
  &__checkbox {
    display: flex;
    flex-direction: column;
    font-size: toRem(16);
  }

  &__input {
    flex: 2;
    width: 100%;
  }

  &__input > input,
  select {
    height: toRem(72);
    border: 1px solid $gray;
    font-size: toRem(16);
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    flex-direction: column;
  }

  .common-input {
    flex-direction: column;
  }
}

.tooltip-container {
  display: flex;
  align-items: center;
  padding: 10px;
}
