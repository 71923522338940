// -----------------------------------------------------------------------------
// This file contains all styles related to the banner component.
// -----------------------------------------------------------------------------
.home-banner {
  margin: 0;
  h1 {
    margin: 0;
  }
  background-size: cover !important;
  position: relative;
  transition: padding 0.3s;
  display: flex;
  align-content: center;
  justify-content: left;
  padding: 3rem 1rem;

  //Define the screen overlay
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    //Fix the position of the overlay above the picture
    top: 0;
    left: 0;
  }

  &__base {
    position: relative;
    z-index: 1;
    width: 100%;

    * {
      color: $white;
    }

    h1,
    &__title {
      font-size: toRem(48);
      width: 100%;
    }

    &__detail {
      &--1 {
        font-size: toRem(24);
        font-weight: 500;
        width: 100%;
      }

      &--2 {
        font-size: toRem(18);
        font-weight: 300;
      }

      &--3 {
        font-size: toRem(14);
        font-weight: 300;
      }
    }
  }
  // Increase the text size compared to sm and restrict paragraph width
  @include media-breakpoint-up(md) {
    &__base {
      &__title {
        font-size: toRem(48);
      }

      &__detail {
        &--1 {
          font-size: toRem(24);
        }

        &--2 {
          font-size: toRem(18);
          max-width: 50%;
        }

        &--3 {
          font-size: toRem(14);
          font-weight: 300;
        }
      }
    }
  }

  // Increase the text size compared to sm and restrict paragraph width
  @include media-breakpoint-up(lg) {
    padding: 0;
    height: 450px;
    &__base {
      &__title {
        font-size: toRem(48);
      }

      &__detail {
        &--1 {
          font-size: toRem(24);
        }

        &--2 {
          font-size: toRem(18);
        }

        &--3 {
          font-size: toRem(14);
        }
      }
    }
  }
}
