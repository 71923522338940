@import './../../../assets/styless/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.sub-page {
  &__banner {
    display: none;
  }
}

.sub-options {
  padding: 1.6rem;

  * {
    font: inherit;
  }

  &__title {
    font-size: 2.8rem;
    text-align: center;
  }

  &__item {
    margin-top: 1.6rem;
    &__cost {
      background-color: $milk;
      padding: 3.2rem 3.2rem 1rem 3.2rem;

      * {
        text-align: center;
      }

      &--title,
      &--cost {
        font-size: 2.8rem;
      }

      &--subvention {
        font-size: 1.5rem;
        margin: 4rem 1.6rem 1rem 1.6rem;
        font-weight: normal;
        color: $lightBlack;
      }

      &--title {
        max-width: 28rem;
        margin: 0 auto;
        min-height: 5.5rem;
      }

      &--cost {
        font-weight: 600;
        border-bottom: 0.05rem solid $lightBlack;
        padding-bottom: 1.6rem;
      }

      &--sub-cost {
        font-size: 1.8rem;
        margin-top: 1.5rem;
        font-weight: bold;

        &.hidden {
          visibility: hidden;
          opacity: 0;
        }
      }

      &--note {
        font-size: 1.8rem;
        margin-top: 1.5rem;
        font-weight: bold;
      }

      &--asterisk {
        color: $primary;
        font-size: 2rem;
        vertical-align: text-top;
        font-weight: bold;
      }
    }

    .rent {
      padding-bottom: 2.1rem;
    }

    &__info {
      margin: 1rem 0;
      background-color: $milk;
      padding: 2rem;
      font-size: 1.6rem;
      display: flex;
      gap: 1rem;

      &--rent {
        align-self: center;
      }

      &--price {
        font-weight: bold;
        font-size: 2.5rem;
        flex: 1;
        text-align: center;
      }

      &--detail {
        flex: 1;

        a {
          text-decoration: underline;
          color: $primary;
        }
      }
    }

    &__offer-detail {
      background-color: $white;
      padding: 1.6rem;
    }

    &__btn {
      font-size: 1.8rem;
      width: 80%;
      margin: 0 auto;

      button.btn {
        width: 100%;
      }
    }

    &__include {
      * {
        font-size: 1.6rem;
        line-height: 1.5;
      }

      &__title {
        font-weight: 600;
        margin: 1.6rem 0;
      }

      &__list {
        &__item {
          display: flex;
          padding: 0.8rem 0;
          align-items: center;
          justify-content: space-between;

          &--content {
            display: flex;

            svg {
              width: 2.7rem;
              height: 2.7rem;
              path {
                fill: $gray !important;
              }
            }

            &.active {
              svg {
                path {
                  fill: $secondary !important;
                }
              }
            }

            &__text {
              padding-left: 1.6rem;
            }

            &:not(.active) {
              color: $gray;

              img {
                fill: $gray;
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

    &__extra {
      justify-content: flex-end;

      img {
        width: 1.7rem;
        height: 1.7rem;
      }

      &:not(.active) {
        display: none;
      }

      &:hover + &__detail {
        display: flex;
      }

      &__detail {
        display: none;
        z-index: 1;
        flex-direction: row;
        align-items: flex-start;
        position: absolute;
        width: 300px;
        margin-left: 400px;
        margin-top: 40px;
        background: $milk;

        &__icon {
          padding: 1.8rem 1rem;
        }

        &__text {
          padding: 1.5rem 1rem;
        }
      }
    }

    &__condition {
      padding-top: 1.6rem;
      border-top: 1px solid $grey;
      line-height: 1.5;
      &--title {
        font-size: 1.6rem;
        font-weight: 600;
        padding-bottom: 0.8rem;
      }

      &--detail {
        font-size: 1.4rem;
        white-space: pre-wrap;
        padding-left: 0.4rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sub-page {
    &__banner {
      display: block;
    }
  }

  .sub-options {
    &__title {
      display: none;
    }

    &__options {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }

    &__item {
      margin: 0.8rem;
      max-width: 40rem;
    }

    &__conditions-generales {
      text-align: center;
      font-size: 1.5rem;
      color: $primary;
      text-decoration: underline;
      padding: 2.5rem;
    }
  }
}
