.prices_tooltip {
  position: relative;
  display: inline-block;
}

.prices_tooltip .prices_tooltiptext {
  visibility: hidden;
  width: toRem(400);
  background-color: $tooltipGray;
  color: $black;
  text-align: left;
  padding: toRem(20);

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: toRem(-5);
  left: 105%;
}

.prices_tooltip {
  &:hover {
    .prices_tooltiptext {
      visibility: visible;
    }
  }
}
