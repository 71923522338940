.address-form {
  &__label {
    grid-column: 1 / 3;
  }

  &__npa-input,
  &__city-input {
    grid-column: 3 / 8;
  }

  &__street-input {
    grid-column: 3 / 6;
  }

  &__street-number-input {
    grid-column: 6 / 8;
  }

  &__nb-place-infos {
    grid-column: 4 / 8;
  }
}

@include media-breakpoint-down(sm) {
  .address-form {
    &__label {
      grid-column: 1 / 8;
    }

    &__npa-input,
    &__city-input {
      grid-column: 1 / 8;
    }

    &__street-input {
      grid-column: 1 / 5;
    }

    &__street-number-input {
      grid-column: 5 / 8;
    }
  }
}
