// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$base-font-size: 16px;
/// Main brand color
/// @type Color
$red-re: #ba0029 !default;
$primary: #ba0029;

/// Dark grey
/// @type Color
$dark-grey: #333333 !default;

/// White
/// @type Color
$white: #ffffff !default;

/// Black
/// @type Color
$dark: #000000 !default;
$normal: #f7f7f7 !default;
$gap: 15px;
$grey: #e5e5e5;
$graph3: #e0e0e0;
$lightGray: #f6f7f8;
$gray: #bdbdbd;
$borderRadius: 0;
$label: #9b9b9b;
$labelBlurred: #bdbdbd;
$line: lighten($label, 30%);
$lightBlack: #828282;
$tooltipGray: #e8e8e8;
.text-red-re {
  color: $red-re;
}
.bg-red-re {
  background: $red-re;
}
.max-w-section {
  max-width: toRem(1140);
}

.max-w-standard {
  @include media-breakpoint-up(xl) {
    max-width: toRem(750);
  }
}

.max-w-bloc {
  max-width: toRem(300);
}

.max-w-contact {
  max-width: toRem(600);
}

.vertical-align {
  display: flex;
  align-items: center;
}
.text-xxs {
  font-size: toRem(13);
}
.text-xs {
  font-size: toRem(16);
}
.text-sm {
  font-size: toRem(18);
}
.text-normal {
  font-size: toRem(20);
}
.text-md {
  font-size: toRem(24);
}
.text-lg {
  font-size: toRem(28) px;
}
.text-xl {
  font-size: toRem(48) px;
}

.mt-64 {
  margin-top: toRem(64);
}

.py-24 {
  padding-top: toRem(24);
  padding-bottom: toRem(24);
}

.pt-64 {
  padding-top: toRem(64);
}
.pb-64 {
  padding-bottom: toRem(64);
}

.pt-50 {
  padding-top: toRem(50);
}

.logo-pt-50 {
  padding-top: 0;
  @include media-breakpoint-up(lg) {
    padding-top: toRem(50);
  }
}

.pt-48 {
  padding-top: toRem(48);
}

.pb-48 {
  padding-bottom: toRem(48);
}

.pb-50 {
  padding-bottom: toRem(50);
}

.bg-dark-grey {
  background-color: $dark-grey;
}

.pt-md-20 {
  padding-top: toRem(20);
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

$disabled: rgb(242, 242, 242);
