div[class$='-control'] {
  height: 72px;
}

.autocomplete.full > div {
  width: 100%;
}

.autocomplete > div {
  padding: toRem(10);
  width: 190px;
  border-color: $gray;
  border-radius: initial;
}

.autocomplete > div:hover {
  border-color: hsl(0, 0%, 80%);
}

.autocomplete > .css-1pahdxg-control {
  box-shadow: none;
}

.autocomplete > .css-26l3qy-menu {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
}

.autocomplete.disabled > div {
  background-color: $disabled;
}
