// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.big-btn {
  padding-bottom: 64px;
}
.btn {
  display: block;
  width: fit-content;
  text-align: center;
  text-decoration: none;
  padding: $gap $gap * 2 11px;
  border-radius: $borderRadius;
  border: none;
  font-size: toRem(18);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.05em;

  &_long_text {
    @media (max-width: 769px) {
      padding: 10px 5px 10px;
    }
  }

  @media (min-width: 769px) {
    width: max-content;
    min-width: 200px;
  }

  &--primary {
    background-color: $red-re;
    color: $white;
    &:hover,
    &:active {
      color: $white;
      background-color: darken($red-re, 5%);
      transition: all 500ms;
    }
  }

  &--secondary {
    color: $red-re;
    border: 1px solid $red-re;
    transition: all 500ms;
    a {
      padding-left: 10px;
    }
    &:hover,
    &:active,
    &:visited {
      background-color: rgba($red-re, 0.06);
      transition: all 500ms;
      color: $red-re;
    }

    &:disabled {
      color: $white;
      background-color: $line;
      border: 1px solid $white;
    }
  }

  &:disabled {
    cursor: initial;
    transition: all 500ms;
    background-color: $line;
  }

  &--white {
    color: $primary;
    background-color: $white;
    transition: all 500ms;

    &:hover {
      background-color: $line;
      transition: all 500ms;
    }
  }

  &--blue {
    color: $white;
    background-color: $blue;
    transition: all 500ms;

    &:hover,
    &:active,
    &:visited {
      background-color: darken($blue, 5%);
      transition: all 500ms;
    }
  }

  &--red {
    color: $white;
    background-color: $red-re;
    transition: all 500ms;

    &:hover,
    &:active,
    &:visited {
      color: $white;
      background-color: darken($red-re, 5%);
      transition: all 500ms;
    }
  }

  &--margin-bottom {
    margin-bottom: 30px;
  }

  &--subscription-form {
    min-width: 283px !important;
    grid-column: 3 / 7;
    margin-top: 1.2rem;
    margin-bottom: 5rem;
    @media only screen and (max-width: 576px) {
      grid-column: 2 / 6;
      min-width: initial !important;
    }
  }
}

.contact-btn {
  display: flex !important;
  flex-direction: row;
}

.subscribe-btn {
  background-color: $red-re;
  color: $white;
  text-align: center;
  padding: toRem(10);
  &:hover,
  &:active {
    color: $white;
    background-color: darken($red-re, 5%);
    transition: all 500ms;
  }
}

.download-btn {
  @include media-breakpoint-up(sm) {
    width: toRem(375);
  }
  svg {
    margin-right: toRem(10);
    @include media-breakpoint-up(sm) {
      margin-right: toRem(25);
    }
  }
}

.redirect-btn {
  @include media-breakpoint-up(sm) {
    width: toRem(375);
  }
}
