.bloc-section {
  &__title {
    font-size: toRem(28);
    font-weight: 600;
    margin-bottom: 0.8rem;
    min-height: 5.5rem;
  }

  &__detail {
    color: $black;
    font-size: toRem(16);
    text-align: center;
  }

  &__description {
    color: $black;
    font-size: toRem(16);
    text-align: left;
  }

  &__price {
    color: $black;
    font-weight: 600;
    font-size: toRem(24);
    text-align: center;
    padding-bottom: 2rem;
  }
}
