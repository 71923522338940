@import './../../../assets/styless/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.sub-advantage {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;

  &__title {
    font-size: 2.8rem;
    text-align: center;
    padding-bottom: 1.6rem;
  }

  &__list {
    &__item {
      padding: 1.6rem;
      text-align: center;

      &__icon {
        img {
          width: 6.4rem;
          height: 6.4rem;
        }
      }

      &__content {
        line-height: 1.5;
        max-width: 40rem;
        margin: 0 auto;

        h3 {
          font-size: 1.6rem;
          font-weight: 600;
          padding: 1.6rem 0;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sub-advantage {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;

    &__title {
      padding-bottom: 4.8rem;
    }

    &__list {
      display: flex;
      align-items: center;

      &__item {
        &__icon {
          img {
            width: 10rem;
            height: 10rem;
          }
        }

        &__content {
          h3 {
            font-size: 1.8rem;
          }

          p {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
