.step-title {
  text-align: center;
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
  color: $red-re;
  padding-top: 1rem;
}
.step-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px 0 50px;

  @include media-breakpoint-up(lg) {
    margin-left: 4.2rem;
    margin-right: 4.2rem;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 1200px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
