.faq-list {
  &__group {
    margin-top: 2.4rem;

    &:not(:nth-of-type(1)) {
      margin-top: 4.8rem;
    }

    h3 {
      font-size: 2.2rem;
    }
  }

  &__item {
    margin: 1.6rem 0;
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid $graph3;
    box-sizing: border-box;
    background-color: $white;

    > button {
      border: none;
      outline: none;
      box-shadow: none;
      text-align: left;
      padding: 1rem 1rem;
      display: block;
      width: 100%;
      height: 100px;
      background-color: $lightGray;
      transition: background-color 0.3s;

      h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &--question {
      line-height: 1.2;

      h3 {
        span {
          &:first-of-type {
            color: $red-re;
            font-size: toRem(22);
          }
        }
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 10px;
        opacity: 0.8;
      }
    }

    &--answer {
      line-height: 1.5;
      font-size: toRem(18);
      padding: 0 1.6rem;
      white-space: pre-wrap;
      color: $dark;
      text-align: left;

      &.collapse {
        padding-bottom: 3.5rem;

        &.show {
          + button {
            background-color: transparent;
            transition: background-color 0.3s;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .faq-list {
    &__group {
      h3 {
        font-size: 2.2rem;
      }

      &--answer {
        padding: 0 4rem;
        font-size: 1.8rem;
      }
    }
  }
}

table {
  width: 100%;
}

table,
th,
td {
  border: 1px solid black;
}

th,
td {
  padding: 0.5em;
}

th {
  width: 30%;
  text-align: center;
}

tr {
  background-color: $lightGray;
}

/* Billing rate section */
.billing-rate {
  &__full-and-off-hour {
    vertical-align: middle;
  }

  &__total {
    background-color: $gray;
    font-weight: bold;
  }

  &__column-2 {
    text-align: center;
  }
}

/* Price table section */
.price-table {
  table th {
    text-align: center;
  }

  &__center {
    text-align: center;
  }

  &__info-price {
    display: flex;
    gap: 1rem;
  }

  &__old-price {
    color: $primary;
    flex: 1;
    text-align: right;

    &__center {
      text-align: center;
      margin: auto;
    }

    div {
      color: $black;
    }
  }

  &__new-price {
    color: $primary;
    flex: 1;
    font-weight: bold;
    text-align: left;
  }

  ul {
    padding: revert;
    list-style: revert;
  }

  li {
    list-style: inside;
  }
}

/* Canton of Fribourg, Genève and Valais section */
.fribourg-canton,
.geneve-canton,
.valais-canton {
  a {
    text-decoration: underline;
  }
}

/* Difference between billing and ease section */
.difference-between-billing-and-ease {
  ul {
    padding: revert;
    list-style: revert;
  }

  li {
    list-style: inside;
  }
}

@include media-breakpoint-down(xxs) {
  .vaud-canton {
    table td {
      padding: 0.23em;
    }
  }
}

@include media-breakpoint-down(lg) {
  .vaud-canton {
    &__info-price {
      flex-direction: column;
      gap: unset;
    }

    &__old-price {
      text-align: center;
    }

    &__new-price {
      text-align: center;
    }
  }
}
