.cta-section {
  background-color: $normal;
  padding-bottom: 2rem;
  &__content {
    text-align: center;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
  }
}
