@import 'node_modules/bootstrap/scss/mixins';
$primary: #ba0029;
$lightGray: #f6f7f8;
$black: black;
$gray: #bdbdbd;
$grey: #e5e5e5;
$white: white;
$secondary: #ba0029;
$grid-breakpoints: (
  xxs: 330px,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
.client-number {
  padding: 2.2rem 0;
  border: none !important;

  &__label {
    flex: 1;
  }

  &__container {
    flex: 2;

    &__info1 {
      font-size: 18px;
      color: $primary;
    }

    &__info2 {
      font-size: 16px;
    }
  }
}

select {
  -webkit-appearance: menulist;
}

.confirm-modal-backdrop {
  z-index: 1150;
}

.completed-step {
  display: flex;
  justify-content: center;
}

.separator__option:not(:only-child):last-child {
  border-top: 1px solid $lightGray;
  padding-top: 10px;
  margin-top: 10px;
}

.confirm-modal {
  z-index: 1155;
  .modal-header {
    padding: 25px;
    font-size: 3rem;
  }

  .modal-body {
    padding: 25px;
    font-size: 2rem;
  }
}

.validation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-title {
  color: $black;
  font-size: 4rem;
  margin: 0 2rem;
  text-align: center;
}

.validation-subtitle {
  font-size: 1.5rem;
  text-align: center;
  margin: 3rem 0;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  &__parking-address {
    grid-column: 1 / 8;

    &--label {
      font-size: 16px;
      font-weight: bold;
    }

    &--info {
      font-size: 18px;
    }
  }
}

.checkbox {
  grid-column: 1/8;
  font-size: 16px;

  input[type='checkbox'] {
    border-color: $gray;
    border-radius: unset;
    &:checked {
      background-color: $primary;
      background-image: none;
    }

    &:focus {
      box-shadow: rgb(255, 255, 255) 0 0 0 0.25rem;
    }
  }
}

.field {
  grid-column: 1/8;

  &__denomination {
    grid-column: 3/3;
  }

  &__last-name {
    grid-column: 4/8;
  }

  &__street {
    grid-column: 3/6;
  }

  &__street-number {
    grid-column: 6/8;
  }

  &__activation_card {
    grid-column: 1/4;
  }

  &__wall_outlet {
    grid-column: 4/8;
  }

  &__icon_select {
    flex: 1;
  }

  &__total-parking-places {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    font-size: 18px;

    &__info {
      width: 25%;
    }
  }

  &__people-number {
    display: flex;
    gap: 2em;
    padding: 1.2rem 0;

    &__info {
      font-size: 18px;
    }
  }
}

.parking {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  &__separator {
    margin: 2rem 0;
    grid-column: 1 / 8;
  }

  &__btn {
    &-add {
      grid-column: 1 / 5;
      width: 100%;
      margin: 2rem 0 2rem;
    }

    &-delete {
      grid-column: 5 / 8;
      width: 100%;
      margin: 2rem 0 2rem;
    }
  }
}

.icon-select-icon {
  margin: 1em 3em;
}

.icon-select-container {
  display: flex;
  grid-column: 1/8;
  border: 1px solid $grey;
  position: relative;

  .field {
    min-width: 0;
  }

  .icon-select-toolip {
    position: absolute;
    right: -6em;
    top: 40%;
    bottom: 40%;
  }

  select {
    border: none;
  }
}

.click-and-charge-not-installed {
  grid-column: 1 / 8;
  padding: 25px;
  background-color: #d9d9d933;
  display: flex;
  gap: 2.5rem;

  &__title {
    font-size: toRem(32);
  }

  &__detail {
    font-size: 18px;

    a {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.btn_add_subscription {
  grid-column: 1/6;
}

.tutorial {
  font-size: 19px;
  gap: 2em;
  margin: 1em;
  display: flex;
  justify-content: space-between;

  &__title {
    font-weight: bold;
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-right: 1em;

    .icon {
      margin-top: 0.5em;
      display: flex;
      justify-content: space-between;
    }
  }
}

.btn-container-subscription {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 3em;
  border-top: 1px solid $grey;
  padding-top: 3em;

  button {
    grid-column: 4/8;
    width: 100%;
  }
}

.btn-container {
  grid-column: 4/8;
  display: flex;
}

.btn-check-new-address {
  grid-column: 4 / 8;
  display: flex;
  justify-self: flex-end;
}

.btn-next {
  display: flex;
  flex: 1;
  justify-content: center;

  &:focus {
    color: $white;
    background-color: $primary;
  }
}

.btn-check-eligibility {
  padding-right: 0;
  color: $primary;
  text-decoration: underline;
  text-transform: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $primary;
  }
}

.btn-previous {
  font-weight: bold;
  display: flex;
  flex: 1;
  justify-content: center;
}

.btn-summary {
  width: 50%;
  margin: 1em 0;
  display: block !important;
  float: right;
}

.previous-step-container {
  border-bottom: 1px solid $grey;
  padding: 0 0 2rem 0;
  font-size: 18px;
}

.previous-step-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__infos {
    display: flex;
    gap: 20%;
    width: 80%;
  }
}

.previous-step-details {
  * {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  &__mobile {
    display: none;
  }
}

.previous-step-details-title {
  font-weight: bold;
  align-self: start;
}

.subscription-resume-container {
  border-bottom: 1px solid $grey;
  padding: 2rem 0;
  font-size: 18px;
  justify-content: space-between;
  display: flex;

  .parking-information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1;
  }

  .parking-number {
    font-weight: bold;
    color: $primary;
    padding-bottom: 0.5em;
  }

  .subscription-type {
    font-weight: bold;
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    flex: 2;
  }
}

.actions {
  font-weight: bold;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__validation-resume {
    float: right;
    font-size: 18px;
  }

  &__edit {
    text-transform: none;
    color: $primary;
    letter-spacing: normal;
    align-self: start;
    font-weight: bold;
  }
}

.action-label {
  margin-right: 10px;
}

.custom-label-denomination {
  align-items: center;
  display: flex;
}

.black {
  color: $black !important;
}

.page-form {
  .header__logo {
    display: inline-block;
    visibility: visible;
  }

  * {
    line-height: 1.2;
  }

  &__calendly {
    height: 80vh !important;
    .calendly-inline-widget {
      width: 100%;
      height: 690px !important;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      font-size: toRem(16);
    }

    &.loaded {
      height: 100vh !important;
    }
  }

  &__header {
    &__title {
      font-size: toRem(38);
    }
  }

  &__form {
    max-width: unset;
    padding-top: 2.4rem;
    padding-bottom: 18rem;

    &__title {
      color: $secondary;
      margin-bottom: 2.4rem;
      text-align: center;
      max-width: 30rem;
      margin: 0 auto;
    }

    &__title.subscription {
      margin: auto;
      text-align: left;
      padding: 1em 0;
      font-size: toRem(38);
    }

    &__info {
      font-size: toRem(16);
    }

    &__detail {
      text-align: left;
      margin: 0 auto;
      padding: 16px 0;
      font-size: toRem(28);
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;

      &:hover,
      &:active,
      &:visited,
      &:focus {
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: none;
      }
    }

    &__detail.title {
      color: $primary;
    }

    &__describe {
      max-width: 30rem;
      text-align: center;
      font-size: toRem(16);
      margin: 0 auto;
    }

    &__body {
      padding: 2.4rem 0;

      hr {
        margin: 2rem 0;
      }

      .row {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        > div {
          display: inline-flex;
          justify-content: stretch;
        }
      }

      label {
        padding: 0;
        margin-top: 0.4rem;
        font-size: toRem(18);
        line-height: 1.4;
        white-space: pre-wrap;
      }
    }

    &__btn {
      text-align: center;
      margin-top: 16px;

      .btn {
        display: block;
        width: 100%;
        &--red {
          position: relative;
          cursor: pointer;

          &:disabled {
            color: $white;
            background-color: $secondary;
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.subscribe-modal {
  * {
    border-radius: 0 !important;
  }
  .modal-dialog {
    width: 100%;
    max-width: unset;
    margin: 0;
    border-radius: 0;

    .modal-content {
      min-height: 100vh;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__close-btn {
      border: none;
      outline: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
      background: none;

      img {
        width: 2.4rem;
        height: 2.4rem;
      }

      svg {
        fill: black;
      }
    }
  }

  &__form {
    p.validation-text {
      padding-top: 0;
      font-size: 1.4rem;
      font-weight: 600;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;

      &.shown {
        padding-top: 0.8rem;
        max-height: 10rem;
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }
    }

    &.submitted {
      .error-form {
        border-color: $secondary;
      }
    }
    &__accordion {
      &__item {
        display: flex;
        flex-direction: column-reverse;

        .collapse {
          & + button {
            position: relative;
          }

          &.show {
            & + button {
              &.show-icon {
                &::after {
                  content: '-';
                  margin: 0;
                  padding: 0;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  font-size: 3.2rem;
                  font-weight: 100;
                }
              }
            }
          }

          &:not(.show) {
            & + button {
              &.show-icon {
                &::after {
                  content: '+';
                  margin: 0;
                  padding: 0;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  font-size: 2.4rem;
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
    }

    &__input {
      input {
        flex: 1;
      }

      .gender-select {
        margin-right: 16px;
      }

      &--street {
        input {
          &:nth-of-type(1) {
            flex: 1;
          }

          &:nth-of-type(2) {
            max-width: 8rem;
            margin-left: 16px;
          }
        }
      }

      &--locate {
        input {
          &:nth-of-type(2) {
            flex: 1;
          }

          &:nth-of-type(1) {
            max-width: 8rem;
            margin-right: 16px;
          }
        }
      }
    }

    &__btn {
      margin-top: 2.4rem;

      a.condition {
        display: block;
        margin-top: 3.2rem;
        color: $secondary;
      }
    }

    &__note {
      justify-content: flex-end !important;
    }

    &__term {
      display: flex;
      align-items: flex-start !important;
      margin-bottom: 16px;
      margin-top: 0.8rem;

      > span {
        margin-left: 1.2rem;
        line-height: 1.5;
        font-size: 1.3rem;
      }
      a.condition {
        color: $secondary;
        text-decoration: underline;
      }
    }
  }
}

.doubleFormCheckBox {
  display: revert;
}

.doubleFormCheckBox div {
  margin: 10px;
}

.summary {
  border-bottom: 1px solid $grey;
  font-size: 18px;
  padding: 1.5rem 0;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;

  .full {
    grid-column: 1 / 6;
  }

  .title {
    font-weight: bold;
    padding: 5px 0;
  }

  .important {
    color: $primary;
  }

  &--label {
    grid-column: 1 / 5;
  }

  &--value {
    grid-column: 5 / 6;
  }
}

.summary-checkboxes {
  font-size: 18px;
  padding: 1.5rem 0;
}

.equip-parking {
  &__checkbox {
    grid-column: 1 / 8;

    &__consent-use-data {
      &__label {
        width: auto;
        display: inline;
      }

      &__input {
        width: auto;
        display: inline-block;
      }
    }
  }

  &__btn {
    grid-column: 3 / 6;

    .btn--red {
      &:disabled {
        color: $white;
        background-color: $primary;
        opacity: 0.3;
      }
    }
  }
}

.calendly-close-btn {
  top: 57rem;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
}

@include media-breakpoint-up(sm) {
  .page-form {
    &__form {
      max-width: 57rem;
      margin: 0 auto;
    }

    &__calendly {
      &__info {
        width: 90%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .page-form {
    .header__logo {
      visibility: hidden;
    }

    * {
      line-height: 1.2;
    }

    &__calendly {
      height: 100vh;

      &__info {
        width: 100%;
      }
    }

    &__form {
      max-width: toRem(650);
      margin: 0 auto;

      &__title {
        font-size: 48px;
        margin-bottom: 2.4rem;
        max-width: unset;
        text-align: left;
      }

      &__title.subscription {
        font-size: toRem(38);
      }

      &__info {
        font-size: toRem(18);
      }

      &__describe {
        font-size: 18px;
        max-width: unset;
        text-align: left;
      }

      &__body {
        padding: 2.4rem 0;

        .row {
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }

        label {
          padding: 0;
        }
      }

      &__btn {
        .btn {
          display: inline-block;
          width: auto;
          margin: 0 auto;
          padding-left: 4.2rem;
          padding-right: 4.2rem;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .subscription-resume-container {
    flex-direction: column;

    .parking-information-container {
      display: block;

      .subscription-type {
        float: right;
        font-weight: bold;
      }
    }

    .actions-container {
      flex-direction: row;
    }
  }

  .btn_add_subscription {
    grid-column: 1/8;
  }

  .btn-container-subscription button {
    grid-column: 1/8;
  }

  .icon-select-toolip {
    display: none;
  }

  .confirm-modal {
    .modal-footer {
      justify-content: center;
    }
  }

  .page-form {
    &__form {
      max-width: 57rem;
      margin: 0 10px;
    }
  }

  .btn-container {
    grid-column: 1/8;
  }

  .custom-label-denomination {
    grid-column: 1/8;
    margin-top: 10px;
    margin-bottom: -1.2em;
  }

  .field {
    &__denomination {
      grid-column: 1/3;
    }

    &__last-name {
      grid-column: 3/8;
    }

    &__street,
    &__street-number {
      grid-column: 1/5;
    }

    &__street-number {
      grid-column: 5/8;
    }

    &__activation_card {
      grid-column: 1/8;
    }

    &__wall_outlet {
      grid-column: 1/8;
    }
  }

  .parking {
    &__btn {
      &-add {
        padding: 20px 0;
        width: auto;
      }

      &-delete {
        padding: 20px 0;
        width: auto;
      }
    }
  }

  .previous-step-details {
    display: none;

    &__mobile {
      display: flex;
      flex-direction: column;
      margin: 1rem 0 0 0;
    }
  }

  .previous-step-container {
    margin-top: 1em;
  }

  .equip-parking {
    &__checkbox {
      flex-direction: row;

      &__consent-use-data {
        grid-column: 1 / 3;
      }
    }

    &__btn {
      grid-column: 2 / 6;
    }
  }

  .client-number {
    flex-direction: column;
  }

  .btn-summary {
    width: 100%;
  }

  .summary {
    &--label {
      grid-column: 1 / 4;
    }

    &--value {
      grid-column: 4 / 6;
    }
  }

  .summary-checkboxes {
    &__checkbox {
      flex-direction: row;
    }
  }
}

@include media-breakpoint-down(md) {
  .click-and-charge-not-installed {
    padding: 15px;
    gap: 1.5rem;

    &__title {
      font-size: 2.3rem;
    }
  }

  .btn-check-new-address {
    grid-column: 1 / 8;
  }
}
