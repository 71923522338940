.benefit-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px 0 10px;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 1200px;
  }
}

btn-section {
  margin-left: 90px;
}
