.faq-section {
  text-align: left;
  border-style: solid;
  border-width: 1px;
  &__title {
    font-size: toRem(28);
    padding: 35px 40px 0 40px;
    margin-bottom: 1rem;
  }

  &__describe {
    color: $black;
    margin-bottom: 1rem;
    font-size: toRem(18);
    padding: 0 40px 35px 40px;
    line-height: 1.3;
  }
}
